import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { graphql, PageProps } from 'gatsby';

import StrapiButton from '../../components/StrapiLink/StrapiButton';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import SEO from '../../components/SEO/SEO';
import Breadcrumb from '../../components/Breadcrumb';
import Divider from '../../../src/components/Divider';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import { ModalRawHTML } from '../../components/RawHTML/ModalRawHTML';
import { SingleEnglishTestPageQuery, InlineCtaComponentFragment } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './english-test.module.scss';

type PageContextType = GlobalPageContext & {
  id: string;
};

const EnglishTestPage: React.FC<PageProps<SingleEnglishTestPageQuery, PageContextType>> = ({ data, pageContext, location }) => {
  const { englishTest } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const [canShowTestForm, setShowTestForm] = useState(false);

  if (!websiteLocale || !englishTest)
    return (
      <p>
        <FormattedMessage id="notFoundTextHeading" defaultMessage="Not found." />
      </p>
    );

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={englishTest.seo?.metaTitle ?? englishTest.pageSettings?.title ?? englishTest.name ?? ''}
        description={englishTest.seo?.metaDescription ?? englishTest.pageSettings?.metaDescription}
        image={englishTest.seo?.metaImage?.url}
        avoidIndexing={englishTest.pageSettings?.avoidIndexing ?? false}
        canonicalURL={englishTest.seo?.canonicalURL}
        keywords={englishTest.seo?.keywords}
        metaSocial={englishTest.seo?.metaSocial}
        structuredData={englishTest.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className={classnames(styles.main, 'container')}>
        <Breadcrumb pageContext={pageContext} lastCrumb={englishTest.name ?? ''}></Breadcrumb>
        <h1 className="title color-a mt-6 mb-0">{englishTest.name}</h1>
        <Divider paddingTop="70px" marginBottom="75px" />
        <ModalRawHTML html={englishTest.activityBuilder?.htmlCode} />
        <div className="columns">
          <div className="column is-8">
            <RichTextField className={classnames(styles.contentText, 'black-2')} content={englishTest.description} />
            {englishTest.link && websiteLocale?.id !== '59' || websiteLocale?.id !== '73' ? <StrapiButton {...englishTest.link} /> : null}
          </div>
        </div>
        <div className={classnames('container page-components', styles['pageComponents'])}>
          {/**
           * TODO - this is only for israel site test form - remove once israel site is migrated to hubspot srm
           */}
          {websiteLocale?.id === '59' || websiteLocale?.id === '73' ? (
            <div className={classnames('container page-components', styles['pageComponents'])}>
              {englishTest.components?.map((component, idx) =>
                (canShowTestForm && component?.__typename === 'Strapi_ComponentPageEmbedCode') ||
                (!canShowTestForm && component?.__typename === 'Strapi_ComponentPageInlineHubspotForm') ? (
                  <ComponentRenderer
                    associateFunction={
                      component?.__typename === 'Strapi_ComponentPageInlineHubspotForm'
                        ? () => setShowTestForm(true)
                        : null
                    }
                    component={component}
                    key={`course-post-component-${component.__typename}-${idx}`}
                    location={location}
                  />
                ) : null,
              )}
            </div>
          ) : null}
          {englishTest.components?.map((component, idx) =>
            !component ||
            (websiteLocale?.id === '59' || websiteLocale?.id === '73' &&
              (component?.__typename === 'Strapi_ComponentPageEmbedCode' ||
                component?.__typename === 'Strapi_ComponentPageInlineHubspotForm')) ? null : (
              <ComponentRenderer component={component} key={`course-post-component-${component.__typename}-${idx}`} />
            ),
          )}
        </div>
      </div>
      {englishTest.ctaComponent && (
        <StrapiInlineCtaComponent {...(englishTest.ctaComponent as InlineCtaComponentFragment)} />
      )}
    </LayoutWrapper>
  );
};

export const query = graphql`
query SingleEnglishTestPage($id: ID!) {
  strapi {
    englishTest(id: $id) {
      data {
        id
        attributes {
          name
          slug
          description
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          activityBuilder: activity_builder {
            ...HTMLComponent
          }
          link {
            ...LinkComponent
          }
          components {
            ...PageComponents
            __typename
            ... on Strapi_ComponentPageTrustPilotWidget {
              heading
              script {
                ...HTMLComponent
              }
            }
          }
          ctaComponent: cta_component {
            ...InlineCTAComponent
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(EnglishTestPage));
